h1,
h2 {
  margin-bottom: 0 !important;
}

h1,
h2 {
  padding: 0 5px;
}

.menu-item-mods-modal-container {
  position: relative
}

.menu-item-mods-modal-title {
  font-size: 20px;
  font-weight: bold;
}

.menu-item-mods-modal-close-btn {
  position: absolute;
  top: -45px;
  right: -45px;
  height: 40px;
}

.menu-item-mods-modal-close-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  z-index: 2;
}

.menu-item-mods-modal-redo-btn {
  position: absolute;
  top: -45px;
  right: 45px;
}

.menu-item-mods-modal-redo-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: aliceblue;
}

.menu-item-mods-selector-list {
  padding: 3px;
}

.menu-item-mods-selector-row {
  min-height: 60px;
  padding: 10px 5px;
  margin-bottom: 8px;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 5px;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  box-shadow: 1px 1px 6px 0px rgba(0, 0, 0, 0.6);
  -webkit-box-shadow: 1px 1px 6px 0px rgba(0, 0, 0, 0.6);
}

.menu-item-mods-selector-row:nth-child(even) {
  /* background-color: #e7e7e7; */
}

.menu-item-mods-modal-sticky-header {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  height: 150px;
  width: 100%;
  z-index: 1000;
}

/* .menu-item-mods-selector-ins {
  font-size: 20px;
  font-weight: bold;
  height: 50px;
  line-height: 50px;
} */

.menu-item-mods-modal-ins-btn {
  position: -webkit-sticky;
  position: sticky;
  bottom: 20px;
  /* left: 0; */
  height: 60px;
  /* transform: translateY(-150%); */
}

.menu-item-mods-selector-list-enter {
  opacity: 0;
}

.menu-item-mods-selector-list-enter-active {
  opacity: 1;
  transition: opacity 250ms;
}

.menu-item-mods-selector-list-exit {
  opacity: 1;
}

.menu-item-mods-selector-list-exit-active {
  opacity: 0;
  transition: opacity 250ms;
}

.menu-item-mods-selector-list-minus:active {
  transform: scale(1.05, 1.05);
}

.menu-item-mods-selector-list-tag-row {
  width: 100%;
  height: 30px;
  font-size: 18px;
  font-weight: bold;
}

@keyframes horizontal-shaking {
  0% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(15px);
  }

  50% {
    transform: translateX(-12px);
  }

  75% {
    transform: translateX(8px);
  }

  85% {
    transform: translateX(-6px);
  }

  90% {
    transform: translateX(5px);
  }

  95% {
    transform: translateX(-3px);
  }

  97% {
    transform: translateX(3px);
  }

  99% {
    transform: translateX(-1px);
  }

  100% {
    transform: translateX(0);
  }

  0% {
    -webkit-transform: translateX(0);
  }

  25% {
    -webkit-transform: translateX(15px);
  }

  50% {
    -webkit-transform: translateX(-12px);
  }

  75% {
    -webkit-transform: translateX(8px);
  }

  85% {
    -webkit-transform: translateX(-6px);
  }

  90% {
    -webkit-transform: translateX(5px);
  }

  95% {
    -webkit-transform: translateX(-3px);
  }

  97% {
    -webkit-transform: translateX(3px);
  }

  99% {
    -webkit-transform: translateX(-1px);
  }

  100% {
    -webkit-transform: translateX(0);
  }
}