.scan-load-mask-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  justify-content: center;
  /* align-items: center; */
  background: rgba(0, 0, 0, 0.3);
  z-index: 9999;
}

.scan-load-mask-spinner-wrapper {
  position: relative;
}

.scan-load-mask-icon {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translateY(-50%);
}