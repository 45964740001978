.menu-grouper-container {
  width: 100%;
  text-align: center;
}

.menu-group-title-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.menu-group-title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: bold;
}

.menu-group-title span {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.menu-group-content {
  box-sizing: border-box;
}

.slide-in {
  transform: translateY(0%);
}

.slide-out {
  transform: translateY(100%);
}

.menu-group-item-card {
  display: flex;
  margin: 10px;
  box-sizing: border-box;
}

.menu-group-item-pill {
  margin: 15px 20px 20px 15px;
  box-sizing: border-box;
}

.menu-group-content-pill {
  box-sizing: border-box;
}