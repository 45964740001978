.admin-POS-wrapper {
  height: 100vh;
  overflow: scroll;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
  padding: 5px 5px 100px 5px;
}

.admin-POS-wrapper::-webkit-scrollbar {
  display: none;
}

.admin-POS-btns {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.simple-keyboard.hg-layout-default .hg-button.hg-primary {
  background: var(--primary);
  color: white;
}