.menu-popup-item-selector-sticky-header {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  /* right: 0;
  left: 0; */
  height: 80px;
  z-index: 1000;
}

.menu-popip-item-selector-ins {
  font-size: 20px;
  font-weight: bold;
  height: 30px;
}

.menu-popup-item-selector-list-enter {
  opacity: 0;
}

.menu-popup-item-selector-list-enter-active {
  opacity: 1;
  transition: opacity 250ms;
}

.menu-popup-item-selector-list-exit {
  opacity: 1;
}

.menu-popup-item-selector-list-exit-active {
  opacity: 0;
  transition: opacity 250ms;
}

@keyframes horizontal-shaking {
  0% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(15px);
  }

  50% {
    transform: translateX(-12px);
  }

  75% {
    transform: translateX(8px);
  }

  85% {
    transform: translateX(-6px);
  }

  90% {
    transform: translateX(5px);
  }

  95% {
    transform: translateX(-3px);
  }

  97% {
    transform: translateX(3px);
  }

  99% {
    transform: translateX(-1px);
  }

  100% {
    transform: translateX(0);
  }

  0% {
    -webkit-transform: translateX(0);
  }

  25% {
    -webkit-transform: translateX(15px);
  }

  50% {
    -webkit-transform: translateX(-12px);
  }

  75% {
    -webkit-transform: translateX(8px);
  }

  85% {
    -webkit-transform: translateX(-6px);
  }

  90% {
    -webkit-transform: translateX(5px);
  }

  95% {
    -webkit-transform: translateX(-3px);
  }

  97% {
    -webkit-transform: translateX(3px);
  }

  99% {
    -webkit-transform: translateX(-1px);
  }

  100% {
    -webkit-transform: translateX(0);
  }
}