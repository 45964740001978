.order-options-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
}

.order-options-background-container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: -1;
}

.order-options-content-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
}

.order-options-menu-btn {
  position: absolute;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  left: 100%;
  top: 100%;
  transform: translate(-60%, -70%);
  z-index: 2;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.6);
  -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.6);
}

.order-options-menu-btn-icon-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
}

.order-options-menu-btn-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  /* z-index: 1000; */
}

.order-options-menu-content-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: -20px;
}

.order-options-menu-content {
  width: 120px;
  /* backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px); */
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 999;
}

.order-options-cancel-btn-wrapper {
  position: absolute;
  top: -18px;
  right: -18px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  z-index: 2;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.6);
  -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
}

.order-options-back-btn-wrapper {
  position: absolute;
  top: 50%;
  left: 0;
  width: 50px;
  height: 50px;
  transform: translateX(-65%) translateY(-50%);
  border-radius: 50%;
  z-index: 2;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.6);
  -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
}

.order-options-forward-btn-wrapper {
  position: absolute;
  top: 50%;
  right: 0;
  width: 50px;
  height: 50px;
  transform: translateX(65%) translateY(-50%);
  border-radius: 50%;
  z-index: 2;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.6);
  -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
}

.order-options-forward-btn-wrapper:active,
.order-options-back-btn-wrapper:active,
.order-options-cancel-btn-wrapper:active {
  background-color: rgba(211, 211, 211, 0.9);
  color: #666;
}

.order-options-no-order-option {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}


.order-options-step-two-left-scrollable {
  overflow: scroll;
  scrollbar-width: none;
  /* Firefox */
  transform: translateX(-300%);
  opacity: 0;
}

.order-options-step-two-left-scrollable::-webkit-scrollbar {
  display: none;
}

.order-option-content-switch-enter {
  transform: translateX(100%);
}

.order-option-content-switch-enter-active {
  transform: translateX(0%);
  transition: transform 400ms ease-out;
}

.order-option-content-switch-exit {
  transform: translate(0%, 0%);
}

.order-option-content-switch-exit-active {
  transform: translate(-130%, 0%);
  transition: transform 400ms ease-in;
  transition-delay: 200ms;
}

.order-option-order-btn-transition-enter {
  transform: translate(0, 1000%);
}

.order-option-order-btn-transition-enter-active {
  /* opacity: 1; */
  transform: translate(0, 0);

  transition: transform 400ms ease-out;
  transition-delay: 500ms;
}

.order-option-order-btn-transition-exit {
  /* opacity: 1; */
  transform: translate(0, 0);
}

.order-option-order-btn-transition-exit-active {
  transform: translateY(1000%);
  transition: transform 400ms ease-in;
}

.order-option-order-btn-wrapper {
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 30%);
}

.order-option-step-two-right-side {
  overflow: scroll;
  scrollbar-width: none;
  /* Firefox */
}

.order-option-step-two-right-side::-webkit-scrollbar {
  display: none;
}

.order-option-schedule-option-container {
  /* transform: translateY(300px); */
  transform: translate(0px, 300px);
}

.order-option-order-btn {
  width: 250px;
  height: 60px;
  display: flex;
  justify-content: center;
}

.order-option-step-two-schedule-content {
  transform: translateX(300%);
}

@keyframes menuShow {
  0% {
    transform: translate(-20px, 0);
    height: 0;
  }

  100% {
    transform: translate(-20px, -150px);
    height: 140px;
  }
}

@keyframes scheduleOptionMoveUp {
  0% {
    transform: translateY(300px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes showDates {
  0% {
    transform: translateX(-300%);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes showScheduleContent {
  0% {
    transform: translateX(300%);
  }

  100% {
    transform: translateX(0);
  }
}