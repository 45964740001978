@import '~antd/dist/antd.css';

:root {
  --primary: #004879;
  --secondary: #477900;
  --adminPrimary: #004879;
  --adminSecondary: #f0ad4e;
  --btnColor: #fff;
  /* --fontOne: 'Segoe UI';
  --fontTwo: Arial;
  --fontThree: Times;
  --accent: #fff;
  --fontVariationSettings: normal;
  --colorOne: #000000;
  --colorTwo: #fff;
  --backdrop: rgba(0, 0, 0, 0.3);
  --bgColor: #f0f2f5;
  --textTransform: none; */
}

@font-face {
  font-family: 'NoExit';
  font-style: normal;
  src: url(./assets/fonts/NoExit-Octagon-Variable.ttf);
  /* font-variation-settings: 'wght' 500, 'wdth' 600 !important; */
}

@font-face {
  font-family: "CaslonIonic";
  src: url(./assets/fonts/CaslonIonic-Regular.otf) format("opentype");
}

.site-layout-content {
  height: 100%;
}

.ant-drawer-body {
  padding: 5px;
  height: 50vh;
  overflow: scroll;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.ant-drawer-body::-webkit-scrollbar {
  display: none;
}

.ant-tabs-tab {
  height: 40px;
  border-top-left-radius: 6px !important;
  border-top-right-radius: 6px !important;
  box-shadow: 1px 1px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.75);
}

.ant-tabs-tab.ant-tabs-tab-active {
  border-bottom: 5px solid var(--adminSecondary) !important;
  z-index: 2;
}

.ant-radio-group.ant-radio-group-outline {
  width: 100%;
}

.ant-radio-wrapper {
  width: 100%;
  margin-bottom: 20px;
  align-items: center;
}

.ant-radio-wrapper.ant-radio-wrapper-checked {
  margin-bottom: 20px;
  align-items: center;
}

.ant-radio {
  margin-top: -4px;
}

.ant-radio.ant-radio-checked {
  margin-top: -4px;
}

.ant-radio-checked .ant-radio-inner {
  border-color: var(--primary) !important;
}

.ant-radio-checked input:active {
  border-color: var(--primary)
}

.ant-radio-checked .ant-radio-inner:after {
  background-color: black;
}

.ant-radio:hover .ant-radio-inner {
  border-color: black;
}

.ant-radio {
  /* width: 0;
  height: 0; */
  opacity: 0 !important;
}

.ant-modal-wrap {
  /* overflow: scroll; */
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.ant-modal-wrap::-webkit-scrollbar {
  display: none;
}

.ant-modal-content {
  position: relative;
  border-radius: 20px;
}

.ant-menu {
  background-color: var(--adminPrimary) !important;
}

.ant-menu-item.ant-menu-item-selected.ant-menu-item-only-child {
  height: 45px;
  font-size: 16px;
  font-weight: bolder;
  padding-left: 10px !important;
}

.ant-menu-submenu-title {
  font-size: 16px;
  padding-left: 10px !important;
}

.ant-menu-item.ant-menu-item-only-child {
  height: 45px;
  font-size: 16px;
  padding-left: 10px !important;
}

.ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-open > ul > li {
  padding-left: 15px !important;
}

.ant-picker-dropdown.ant-picker-dropdown-range.ant-picker-dropdown-placement-bottomRight {
  /* zoom: 1.15; */
}

.ant-picker-range-arrow {
  z-index: -1;
}

.ant-pagination-prev {
  margin-right: 30px;
}

.ant-pagination-prev.ant-pagination-disabled {
  margin-right: 30px;
}

.ant-pagination-next {
  margin-left: 30px;
}

.ant-pagination-next.ant-pagination-disabled {
  margin-left: 30px;
}

.ant-btn.ant-btn-primary.ant-btn-sm {
  background-color: var(--adminPrimary);
  border-color: var(--adminPrimary);
}

.ant-btn.ant-btn-primary.ant-btn-lg.ant-input-search-button {
  background-color: var(--adminPrimary);
  border-color: var(--adminPrimary);
}

.ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
  border-color: var(--adminPrimary) !important;
  background-color: var(--adminPrimary) !important;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: var(--adminPrimary) !important;
}

.ant-checkbox-checked .ant-checkbox-inner,
.ant-checkbox-indeterminate .ant-checkbox-inner {
  background-color: var(--adminPrimary) !important;
  border-color: var(--adminPrimary) !important;
}

.ant-checkbox-checked::after {
  border-color: var(--adminPrimary) !important;
}

.ant-checkbox.ant-checkbox-checked.ant-checkbox-disabled .ant-checkbox-inner {
  background-color: #999 !important;
  border-color: #999 !important;
}

.ant-menu-item.ant-menu-item-selected.ant-menu-item-only-child {
  background-color: var(--adminSecondary) !important;
}

.ant-input-affix-wrapper.ant-input-affix-wrapper-focused {
  border-color: var(--primary);
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.05);
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: var(--primary);
}

.ant-input-search .ant-input:hover,
.ant-input-search .ant-input:focus,
.ant-input-number:focus,
.ant-input-number:hover {
  border-color: var(--primary) !important;
  outline: none;
}

.ant-select.ant-select-single.ant-select-show-arrow.ant-select-open .ant-select-selector {
  border-color: var(--primary);
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.05);
}

.ant-select-focused .ant-select-selector,
.ant-select-selector:focus,
.ant-select-selector:active,
.ant-select-selector:hover,
.ant-select-open .ant-select-selector {
  border-color: var(--primary) !important;
  box-shadow: none !important
}

.ant-pagination-item.ant-pagination-item-active {
  border-color: var(--primary);
}

.ant-pagination-item-active a {
  color: var(--primary);
}

.ant-pagination-item-active:hover a {
  color: var(--primary);
}

.ant-pagination-prev a {
  color: var(--primary);
}

.ant-pagination-prev.ant-pagination-disabled a {
  color: #999;
}

.ant-pagination-next a {
  color: var(--primary);
}

.ant-pagination-next.ant-pagination-disabled a {
  color: #999;
}

.ant-input:hover,
.ant-input:focus,
.ant-input-focused {
  outline: none !important;
  border-color: var(--primary) !important;
  box-shadow: none;
}

textarea:focus {
  outline: none !important;
  box-shadow: none;
  border-color: var(--primary) !important;
}

textarea.ant-input,
.ant-input {
  outline: none !important;
  border: 1px solid transparent;
  box-shadow: none;
  color: var(--primary);
}


.ant-picker-ok .ant-btn.ant-btn-primary.ant-btn-sm {
  color: var(--btnColor);
}

.ant-picker:hover,
.ant-picker-focused {
  border-color: var(--primary);
}

.ant-picker-input > input:focus,
.ant-picker-input > input-focused {
  border-color: var(--primary) !important;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background: var(--primary);
}

.ant-picker-range .ant-picker-active-bar {
  background: var(--primary);
}

.ant-dropdown-menu-item-selected,
.ant-dropdown-menu-submenu-title-selected {
  color: var(--primary);
}

.ant-dropdown-trigger.ant-table-filter-trigger.active,
.anticon.anticon-caret-up.ant-table-column-sorter-up.active,
.anticon.anticon-caret-down.ant-table-column-sorter-down.active {
  color: var(--primary);
}

.ant-btn.ant-btn-link.ant-btn-sm {
  color: var(--primary);
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--primary);
}

.ant-message,
.ant-message span.anticon {
  font-size: 24px;
}