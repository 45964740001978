h3 {
  margin-bottom: 0 !important;
}

.menu-pizza-item-topping-row {
  min-height: 60px;
  padding: 10px 5px;
  margin-bottom: 8px;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 5px;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  box-shadow: 1px 1px 6px 0px rgba(0, 0, 0, 0.6);
  -webkit-box-shadow: 1px 1px 6px 0px rgba(0, 0, 0, 0.6);
}

.menu-pizza-item-topping-row:nth-child(even) {
  /* background-color: #e7e7e7; */
}