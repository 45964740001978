.menu-picker-container {
  width: 100%;
  padding-bottom: 30px;
  overflow: scroll;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
  position: relative;
}

.menu-picker-container::-webkit-scrollbar {
  display: none;
}