.preview-menu-wrap {
  display: flex;
  flex-direction: row;
  height: 100vh;
}

.preview-close-btn-icon {
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 14px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.7);
}

.preview-right-wrap {}