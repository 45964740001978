.admin-image-uploader-transition-enter {
  opacity: 0;
}
.admin-image-uploader-transition-enter-active {
  opacity: 1;
  transition: opacity 800ms ease-in-out;
}
.admin-image-uploader-transition-exit {
    opacity: 1;
}
.admin-image-uploader-transition-exit-active {
    opacity: 0;
  transition: opacity 800ms ease-in-out;
}
