.vt-order-row-wrapper {
  position: relative;
  margin-bottom: 7px;
}

.vt-order-row-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  margin-bottom: 3px;
}

.vt-order-row-edit {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 20%;
  height: 100%;
  box-sizing: border-box;
}

.vt-order-row-qty {
  font-weight: bold;
}

.vt-order-row-minus {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 100%;
}

.vt-order-row-plus {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 100%;
}

.vt-order-row-delete {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 100%;
}

.vt-order-row-minus:active {
  transform: scale(1.05, 1.05);
}

.vt-order-row-plus:active {
  transform: scale(1.05, 1.05);
}

.vt-order-row-delete:active {
  transform: scale(1.05, 1.05);
}

.vt-order-row-main {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  height: 100%;
  padding: 0 5px;
  box-sizing: border-box;
}

.vt-order-row-main-title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 80%;
  font-weight: bold;
}

.vt-order-row-main-title span {
  /* text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; */
}

.vt-order-row-main-price {
  width: 15%;
  font-weight: bold;
}

.vt-order-row-expand {
  display: flex;
  flex-direction: row;
  justify-content: right;
  width: 5%;
  /* padding: 0 5px; */
  box-sizing: border-box;
}

.vt-order-row-badge {
  --f: 10px;
  /* control the folded part*/
  --r: 15px;
  /* control the ribbon shape */
  --t: -8px;
  /* the top offset */

  position: absolute;
  top: -8px;
  left: 100%;
  transform: translateX(-82%);

  inset: var(--t) calc(-1 * var(--f)) auto auto;
  padding: 0 5px var(--f) calc(5px + var(--r));
  clip-path: polygon(0 0,
      100% 0,
      100% calc(100% - var(--f)),
      calc(100% - var(--f)) 100%,
      calc(100% - var(--f)) calc(100% - var(--f)),
      0 calc(100% - var(--f)),
      var(--r) calc(50% - var(--f) / 2));
  box-shadow: 0 calc(-1 * var(--f)) 0 inset #0005;
  font-weight: bold;
  font-size: 16px;
}

.vt-order-row-mod-item-exit {
  transform: translateX(0%);
}

.vt-order-row-mod-item-exit-active {
  transform: translateX(-100%);
  transition: transform 500ms ease-in-out;
}

/* .vt-order-sub-rows-container-enter {
  transform: translateY(-100%);
}

.vt-order-sub-rows-container-enter-active {
  transform: translateY(0%);
  transition: transform 500ms ease-in-out;
} */

.vt-order-sub-rows-container-exit {
  transform: translateY(0);
}

.vt-order-sub-rows-container-exit-active {
  transform: translateY(-100%);
  transition: transform 500ms ease-in-out;
}