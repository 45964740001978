.vt-card-container {
  border-radius: 5%;
  position: relative;
  overflow: hidden;
}

.vt-card-container:active {
  transform: translateY(3px);
  transition: transform 0.3s ease-out;
}

.vt-card-with-img,
.vt-card-slider-without-img {
  animation: vtcardslidein 0.3s ease-in;
}

.vt-card-img-wrap {
  width: 100%;
  /* padding: 3px; */
}

.vt-card-with-img-meta {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
}

.vt-card-with-img-meta-title {
  font-weight: bold;
  backdrop-filter: blur(5px);
  padding: 0 3px;
}

.vt-card-with-img-meta-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 4px;
  padding: 0 15px;
}

.vt-card-meta {
  display: flex;
  flex-direction: column;
}

.vt-card-meta-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-top: 3px;
}

.vt-card-title-content {
  padding: 0 3px;
  font-weight: bold;
}

.vt-card-meta-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 15px;
  margin-bottom: 10px;
}

@keyframes vtcardslidein {
  0% {
    transform: translateX(50px);
  }
}