/* .scan-barcode-input {
  position: absolute;
  top: 0;
  right: 0;
} */

.scan-barcode-input {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 0;
  border: none;
  background-color: transparent;
}

.scan-barcode-input:focus {
  outline: none;
}

.scan-right-ins {
  width: 80%;
  height: 70px;
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.35);
  border-radius: 5px;
  margin-top: 30px;
  background-color: transparent;
}

.scan-right-ins-img-wrapper {
  height: auto;
  width: 120px;
  background-color: #f0f4f5;
  overflow: hidden;
}

.scan-right-ins-img-wrapper > img {
  height: 70px;
  width: 100px;
  object-fit: cover;
  border-radius: 0 28px 28px 0 / 0 42px 42px 0;
  box-shadow: 3px 5px 1px 3px rgba(0, 0, 0, 0.35);
}

.scan-right-ins-text {
  width: 70%;
  height: 70px;
  font-size: 20px;
  font-weight: bold;
  line-height: 70px;
  text-align: center;
  /* color: #fff; */
}

.scan-employee-login-modal-close-btn {
  position: absolute;
  top: -35px;
  right: -35px;
  z-index: 999;
}

.scan-employee-login-modal-close-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: aliceblue;
}

.scan-employee-login-modal-login-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 998;
  background-color: #f0f4f5;
}

.scan-employee-login-modal-login-content {
  position: relative;
}

.scan-right-key-in-code-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 998;
  background-color: #f0f4f5;
}

.scan-page-close-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  border-radius: 8px;
  box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.75);
}

.scan-page-control-buttons-wrap {
  position: absolute;
  top: 0;
  right: 0;
}

.scan-page-control-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px;
}

.scan-page-control-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  border-radius: 8px;
  box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.75);
  font-weight: bold;
  margin-bottom: 10px;
}

.scan-instruction-row {
  position: absolute;
  top: 10px;
  left: 25%;
  transform: translateX(-13%)
}