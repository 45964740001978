.vt-pill-wrap {
  padding: 10px;
  transform-origin: top left;
  overflow: hidden;
}

.vt-pill-wrap:active {
  transform: translateY(3px);
  transition: 0.3s ease-in;
}

.vt-pill-container {
  animation: vtcardslidein 0.3s ease-out;
  padding: 5px 0;
}

.vt-pill-info-meta-title {
  font-weight: bold;
  font-size: 18px;
}

.vt-pill-info-meta-price {
  font-weight: 500;
  font-size: 18px;
}

.vt-pill-info-meta-des p {
  text-align: left;
  margin: 0;
  /* font-weight: 500; */
  font-size: 14px;
}

.vt-pill-info-meta-cal {
  font-size: 18px;
}


@keyframes vtcardslidein {
  0% {
    transform: translateX(50px);
  }
}