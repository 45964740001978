.menu-wrap {
  height: 100vh;
  width: 100vw;
  position: relative;
}

.menu-bgImage-wrap {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: -1;
}

.menu-header-wrap {
  width: 100%;
  box-sizing: border-box;
}

.menu-header-logo-row {
  padding: 10px 8px 3px;
}

.menu-header-tabs-row {
  display: flex;
  flex-wrap: no-wrap;
  overflow-x: auto;
  width: 100%;
  margin-top: 10px;
  padding: 0 15px;
}

.menu-header-tab-card {
  padding: 8px 12px;
  font-weight: bold;
  margin-right: 10px;
}

.menu-header-cart-wrap {
  position: relative;
}

.menu-header-cart-count {
  position: absolute;
  top: -1px;
  right: 8px;
  font-weight: bold;
  font-size: 18px;
  width: 30px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu-picker-wrap {
  width: 100%;
}

.menu-order-items-pane {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.menu-order-items-pane::-webkit-scrollbar {
  display: none;
}

.order-drawer-close-btn {
  font-size: 36px;
  color: #999;
  text-align: center;
}

.menu-cancel-modal-close-btn {
  position: absolute;
  top: -55px;
  right: -35px;
}

.menu-cancel-modal-close-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: aliceblue;
}