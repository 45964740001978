.order-list-pane {
  padding: 10px 15px;
  margin: 10px 0 0;
  box-sizing: border-box;
  overflow: scroll;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
  width: 100%;
}

.order-list-pane::-webkit-scrollbar {
  display: none;
}

.order-list-item-enter {
  transform: translateX(100%);
}

.order-list-item-enter-active {
  transform: translateX(0%);
  transition: transform 300ms ease;
}

.order-list-item-exit {
  transform: translate(0%, 0%);
}

.order-list-item-exit-active {
  transform: translate(-130%, 0%);
  transition: transform 300ms ease-in;
}