.start-btm-touch-wrapper {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 5%;
}

.start-touch-hand-wrapper {
  position: relative;
  overflow: hidden;
  width: 100px;
  height: 160px;
  margin: 0 auto;
}

.start-touch-circle {
  width: 82px;
  height: 62px;
  margin: 30px 0 -70px 2px;
  text-align: center;
  animation: circleHide 2s ease infinite both;
}

.start-touch-hand {
  width: 100px;
  height: 100px;
  margin: 0 auto;
  animation: fingerHandle 2s ease infinite both;
}

@keyframes fingerHandle {
  0% {
    transform: none;
  }
  70% {
    transform: scale3d(0.8, 0.8, 0.8);
  }
  100% {
    transform: none;
  }
}

@keyframes circleHide {
  0% {
    opacity: 0;
    transform: scale3d(0, 0, 0);
  }
  70% {
    opacity: 1;
    transform: scale3d(1.1, 1.1, 1.1);
  }
  100% {
    opacity: 0;
    transform: scale3d(0, 0, 0);
  }
}
