.login-wrapper {
  width: 100%;
  height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.login-background-container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: -1;
}

.login-center-box {
  width: 852px;
  height: 652px;
  perspective: 3000px;
  -webkit-perspective: 3000px;
}

.login-center-box-content {
  position: relative;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
}

.login-options-box,
.login-operations-box {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border-radius: 16px;

  /* background: rgba(255, 255, 255, 0.7); */
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  /* backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px); */
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.login-options-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
  /* 3d box */
  /* transform: translateZ(326px); */
}

.login-operations-box {
  /* 3d box */
  /* transform:  translateY(326px) rotateX(-90deg); */

  /* X-axis flip */
  /* transform:  rotateX(-90deg); */

  /* Y-axis flip */
  transform: rotateY(180deg);
}

.simple-keyboard {
  max-width: 950px;
}

.hg-button.hg-standardBtn {
  /* width: 72px; */
  height: 60px;
}

.hg-button.hg-functionBtn.hg-button-space {
  height: 60px;
}

.hg-button.hg-functionBtn.hg-button-tab {
  height: 60px;
  /* width: 80px; */
}

.hg-button.hg-functionBtn.hg-button-lock {
  height: 60px;
  /* width: 85px; */
}

.hg-button.hg-functionBtn.hg-button-shift {
  height: 60px;
  /* width: 150px; */
}

.hg-button.hg-functionBtn.hg-button-enter {
  height: 60px;
  /* width: 110px; */
}

.hg-button.hg-functionBtn.hg-button-bksp {
  height: 60px;
  /* width: 100px; */
}

.hg-button > span {
  font-size: 18px;
}

.simple-keyboard.hg-theme-default.btm-border-16 {
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}

.login-account-error-enter {
  opacity: 0;
}

.login-account-error-enter-active {
  opacity: 1;
  transition: opacity 500ms ease;
}

.login-account-error-exit {
  opacity: 1;
}

.login-account-error-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in;
}

.login-card-input {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 0;
  border: none;
  background-color: transparent;
}

.login-card-input:focus {
  outline: none;
}

@keyframes flip {
  0% {
    /* 3d box */
    /* transform: rotateX(0deg); */

    /* Y-axis flip */
    transform: rotateY(0deg);
  }

  100% {
    /* 3d box */
    /* transform: rotateX(90deg); */

    /* Y-axis flip */
    transform: rotateY(180deg);
  }
}

@keyframes flipBack {
  0% {
    /* transform: rotateX(90deg); */

    transform: rotateY(180deg);
  }

  100% {
    /* transform: rotateX(0deg); */

    transform: rotateY(0deg);
  }
}