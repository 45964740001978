.vt-modal-close-btn-wrap {
  position: absolute;
  top: -20px;
  right: -15px;
}

.vt-modal-close-btn-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}