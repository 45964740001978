.scan-left-list-container {
  width: 100%;
  position: relative;
}

.scan-left-list-rows {
  overflow: scroll;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.scan-left-list-rows::-webkit-scrollbar {
  display: none;
}

.scan-left-list-container::-webkit-scrollbar {
  display: none;
}

.scan-left-list-title-logo-wrapper {
  width: 80%;
  height: 100px;
  max-width: 160px;
  height: 100px;
  display: flex;
  /* margin: 10px auto; */
  justify-content: center;
  align-items: center;
  position: -webkit-sticky;
  position: sticky;
  top: 100px;
}

.scan-left-list-footer {
  position: relative;
  height: 80px;
  margin-top: 20px;
}

.scan-left-list-footer-circle-left,
.scan-left-list-footer-circle-right {
  position: absolute;
  width: 25px;
  height: 25px;
  border-radius: 50%;
}

.scan-left-list-footer-circle-left {
  top: -13px;
  left: -14px;
}

.scan-left-list-footer-circle-right {
  top: -13px;
  right: -14px;
}

.scan-left-list-footer::after {
  left: auto;
  right: -10px;
}