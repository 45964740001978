.order-split-bill-modal-close-btn {
  position: absolute;
  top: -40px;
  right: -40px;
}

.order-split-bill-modal-footer {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  padding: 10px;
  font-size: 18px;
}

.order-split-bill-main-paid-list {
  height: 250px;
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.order-split-bill-main-paid-list::-webkit-scrollbar {
  display: none;
}
