.order-close-btn {
  font-size: 32px;
  color: #999;
  text-align: center;
}

.order-sum-list-item-row,
.order-sum-list-mod-row {
  padding: 10px 5px;
  margin-bottom: 3px;
  border-radius: 2px;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.6);
  -webkit-box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.6);
  font-size: 16px;
}

.order-sum-list-mod-row {}

.order-total-pane {
  padding: 5px;
  box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.6);
  -webkit-box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.6);
  border-radius: 5px;
}

.order-sum-discount-pane {
  overflow: scroll;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.order-sum-discount-pane::-webkit-scrollbar {
  display: none;
}

.order-sum-pane {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
  padding: 0 3px;
}

.order-sum-pane::-webkit-scrollbar {
  display: none;
}

.order-payment-btns-wrapper {
  overflow: scroll;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.order-payment-btns-wrapper::-webkit-scrollbar {
  display: none;
}

.order-multi-payments-wrapper {
  overflow: scroll;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.order-multi-payments-wrapper::-webkit-scrollbar {
  display: none;
}

.order-giftcard-modal-close-btn {
  position: absolute;
  top: -35px;
  right: -35px;
  z-index: 999;
}

.order-giftcard-modal-close-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: aliceblue;
}

.order-one-column-layout {
  overflow: scroll;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.order-one-column-layout::-webkit-scrollbar {
  display: none;
}