.menu-item-size-modal-wrap {
  position: relative;
}

.menu-item-size-modal-close-btn {
  position: absolute;
  top: -40px;
  right: -40px;
}

.menu-item-size-modal-close-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: aliceblue;
}

.menu-item-size-modal-header {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  height: 100px;
  width: 100%;
  z-index: 1001;
}

.menu-item-size-modal-list {
  padding: 3px
}

.menu-item-size-modal-footer {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  padding: 10px;
  font-size: 18px;
}

.menu-item-mods-left {
  position: -webkit-sticky;
  position: sticky;
  top: 10%;
}

.menu-item-mods-left-content {
  position: absolute;
  left: -80px;
  animation: cancelBtn 0.8s ease;
  display: flex;
  flex-direction: column;
}

.menu-item-size-modal-title {
  font-size: 20px;
  font-weight: bold;
}

.menu-item-size-modal-radio-btn {
  height: 60px;
  padding: 0 10px;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 5px;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  box-shadow: 1px 1px 6px 0px rgba(0, 0, 0, 0.6);
  -webkit-box-shadow: 1px 1px 6px 0px rgba(0, 0, 0, 0.6);
}

.menu-item-size-modal-radio-btn:active {
  transform: translateY(3px);
}

.menu-item-size-modal-item-info {
  font-weight: bold;
}

@keyframes horizontal-shaking {
  0% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(15px);
  }

  50% {
    transform: translateX(-12px);
  }

  75% {
    transform: translateX(8px);
  }

  85% {
    transform: translateX(-6px);
  }

  90% {
    transform: translateX(5px);
  }

  95% {
    transform: translateX(-3px);
  }

  97% {
    transform: translateX(3px);
  }

  99% {
    transform: translateX(-1px);
  }

  100% {
    transform: translateX(0);
  }

  0% {
    -webkit-transform: translateX(0);
  }

  25% {
    -webkit-transform: translateX(15px);
  }

  50% {
    -webkit-transform: translateX(-12px);
  }

  75% {
    -webkit-transform: translateX(8px);
  }

  85% {
    -webkit-transform: translateX(-6px);
  }

  90% {
    -webkit-transform: translateX(5px);
  }

  95% {
    -webkit-transform: translateX(-3px);
  }

  97% {
    -webkit-transform: translateX(3px);
  }

  99% {
    -webkit-transform: translateX(-1px);
  }

  100% {
    -webkit-transform: translateX(0);
  }
}