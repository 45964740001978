.admin-sider-logo {
  width: 80%;
  max-width: 160px;
  height: 100px;
  display: flex;
  margin: 10px auto;
  justify-content: center;
  align-items: center;
  
}

.admin-content-container {
  overflow: scroll;
  -ms-overflow-style: none; 
  scrollbar-width: none; 
}

.admin-content-container::-webkit-scrollbar {
  display: none;
}

.admin-content{
  height: 100vh;
  overflow: scroll;
  -ms-overflow-style: none; 
  scrollbar-width: none; 
}

.admin-content::-webkit-scrollbar {
  display: none;
}

.admin-nav-wrapper{
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.admin-nav-wrapper::-webkit-scrollbar {
  display: none;
}