h2 {
  margin-bottom: 0 !important;
}

.menu-pizza-item-modal-close-btn {
  position: absolute;
  top: -40px;
  right: -40px;
}

.menu-pizza-item-modal-close-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: aliceblue;
}

.menu-pizza-item-modal-redo-btn {
  position: absolute;
  top: -45px;
  right: 45px;
}

.menu-pizza-item-modal-redo-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: aliceblue;
}

.menu-pizza-item-modal-left {
  position: -webkit-sticky;
  position: sticky;
  top: 10%;
}

.menu-pizza-item-modal-left-content {
  position: absolute;
  left: -250px;
  width: 220px;
  animation: cancelBtn 0.7s ease-out;
}

.menu-pizza-item-modal-left-content-list {
  width: 220px;
  height: 400px;
  padding-bottom: 25px;
  background-color: #f0f2f5;
  overflow: scroll;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
  position: relative;
}

.menu-pizza-item-modal-left-content-list::-webkit-scrollbar {
  display: none;
}

.menu-pizza-item-modal-left-content-list-title {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin-top: 15px;
  width: 100%;
  font-size: 18px;
  font-weight: bold;
}

.menu-pizza-selector-selected-item {
  height: 35px;
}

.menu-pizza-selector-selected-item:nth-child(even) {
  /* background-color: #e7e7e7; */
}

.menu-pizza-selector-selected-item-enter {
  transform: translateX(100%);
}

.menu-pizza-selector-selected-item-enter-active {
  transform: translateX(0%);
  transition: transform 700ms ease-out;
}

.menu-pizza-selector-selected-item-exit {
  transform: translate(0%, 0%);
}

.menu-pizza-selector-selected-item-exit-active {
  transform: translate(-120%, 0%);
  transition: transform 700ms ease-in;
}

/* .menu-pizza-item-modal-left-btm {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 25px;
  background-color: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(5px);
  overflow: hidden;
}

.menu-pizza-item-modal-left-btm-content {
  transform: rotate(45deg);
} */

.menu-pizza-item-modal-left-content-swipe-up {
  position: absolute;
  top: 50%;
  width: 100%;
  text-align: center;
}

.menu-item-pizza-modal-sticky-header {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  height: 100px;
  z-index: 1000;
}

.menu-item-pizza-selector-ins {
  font-size: 20px;
  font-weight: bold;
  height: 30px;
}

@keyframes cancelBtn {
  from {
    left: -100px;
  }

  to {
    left: -250px;
  }
}