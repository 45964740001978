.vt-order-sub-row-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 3px;
}

.vt-order-sub-row-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  max-width: 85%;
}

/* .vt-order-sub-row-delete {
  width: 15%;
  box-sizing: border-box;
  padding: 0 2px;
} */
.vt-order-sub-row-qty {
  width: 10%;
  box-sizing: border-box;
  padding: 0 2px;
  text-align: center;
}

.vt-order-sub-row-title {
  display: flex;
  width: 75%;
  box-sizing: border-box;
  padding: 0 2px;
}

.vt-order-sub-row-title span {
  /* text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; */
}

.vt-order-sub-row-price {
  width: 15%;
  box-sizing: border-box;
  padding: 0 2px;
}