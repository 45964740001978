.menu-scroller-outer {
  display: flex;
  justify-content: center;
  position: relative;
  /* margin-top: 10px; */
}

.menu-scroller-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2px 5px 5px;
  /* margin: 0 0 10px; */
  box-sizing: border-box;
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.menu-scroller-container::-webkit-scrollbar {
  display: none;
}

.menu-scroller-card {
  display: flex;
  margin-bottom: 10px;
}

.menu-scroller-btm {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 25px;
  overflow: hidden;
}

.menu-scroller-btm-content {
  width: 20px;
  height: 20px;
  background-color: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(5px);
  border: solid var(--secondary);
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}
