.admin-edit-user-wrapper {
  height: 100vh;
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  padding: 5px 5px 100px 5px;
}

.admin-edit-user-wrapper::-webkit-scrollbar {
  display: none;
}
