.res-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
}

.res-background-container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: -1;
}

.res-content-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
}

.res-menu-btns-wrapper {
  position: absolute;
  width: 220px;
  height: 70px;
  /* background-color: aquamarine; */
  left: 100%;
  top: 100%;
  transform: translate(-95%, -65%);
  z-index: 2;
  padding: 5px;
}

.res-menu-btn {
  width: 65px;
  height: 60px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.6);
  -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.6);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.res-cancel-btn-wrapper {
  position: absolute;
  top: -18px;
  right: -18px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  z-index: 2;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.6);
  -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
}

.res-back-btn-wrapper {
  position: absolute;
  top: 50%;
  left: 0;
  width: 50px;
  height: 50px;
  transform: translateX(-65%) translateY(-50%);
  border-radius: 50%;
  z-index: 2;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.6);
  -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
}

.res-forward-btn-wrapper {
  position: absolute;
  top: 50%;
  right: 0;
  width: 50px;
  height: 50px;
  transform: translateX(65%) translateY(-50%);
  border-radius: 50%;
  z-index: 2;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.6);
  -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
}

.res-cancel-btn-wrapper:active,
.res-back-btn-wrapper:active,
.res-forward-btn-wrapper:active {
  background-color: rgba(211, 211, 211, 0.9);
  color: #666;
}

.res-sum-row-text {
  font-size: 16px;
  font-weight: bold;
}

.res-error .res-error-enter {
  opacity: 0;
}

.res-error-enter-active {
  opacity: 1;
  transition: opacity 500ms ease;
}

.res-error-exit {
  opacity: 1;
}

.res-error-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in;
}

.res-switch-content-wrapper {
  padding: 5px;
  overflow: scroll;
  scrollbar-width: none;
  /* Firefox */
}

.res-switch-content-wrapper::-webkit-scrollbar {
  display: none;
}

.res-content-switch-enter {
  transform: translateX(100%);
}

.res-content-switch-enter-active {
  transform: translateX(0%);
  transition: transform 400ms ease-out;
}

.res-content-switch-exit {
  transform: translate(0%, 0%);
}

.res-content-switch-exit-active {
  transform: translate(-130%, 0%);
  transition: transform 400ms ease-in;
  transition-delay: 200ms;
}