.admin-image-manager-wrapper {
  height: 100vh;
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  padding: 5px 5px 100px 5px;
}

.admin-image-manager-wrapper::-webkit-scrollbar {
  display: none;
}

.admin-image-manager-img-wrapper{
    width: 300px;
    height: 200px;
    border: 1px solid #e8e8e8;
    position: relative;
    margin: 15px;
}

.admin-image-manager-img-checkbox-wrapper{
position: absolute;
top: -2px;
left: 0;
}
