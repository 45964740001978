.scan-start-mask-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.3);
  z-index: 999;
}

.scan-start-mask-start-btn-wrapper {
  z-index: 2;
  width: 400px;
  height: 220px;
  border-radius: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 4px 4px 22px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 4px 4px 22px 0px rgba(0, 0, 0, 0.75);
}

.scan-start-mask-start-btn-wrapper:active{
    transform: translateY(3px);
}
