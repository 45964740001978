.menu-package-item-sum-title {
  font-size: 20px;
  font-weight: bold;
}

.menu-item-package-drawer-sticky-header {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  height: 100px;
  z-index: 1000;
}

.menu-package-selector-selected-pane {
  overflow: scroll;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.menu-package-selector-selected-pane::-webkit-scrollbar {
  display: none;
}

.menu-package-selector-selected-item-enter {
  transform: translateX(100%);
}

.menu-package-selector-selected-item-enter-active {
  transform: translateX(0%);
  transition: transform 300ms ease;
}

.menu-package-selector-selected-item-exit {
  transform: translate(0%, 0%);
}

.menu-package-selector-selected-item-exit-active {
  transform: translate(-120%, 0%);
  transition: transform 300ms ease;
}

.menu-item-package-drawer-selector {
  height: 98vh;
  overflow: scroll;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.menu-item-package-drawer-selector::-webkit-scrollbar {
  display: none;
}

.menu-package-item-selector-row {
  padding: 5px 3px;
  min-height: 60px;
  margin-bottom: 8px;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 5px;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  box-shadow: 1px 1px 6px 0px rgba(0, 0, 0, 0.6);
  -webkit-box-shadow: 1px 1px 6px 0px rgba(0, 0, 0, 0.6);
}

.menu-package-item-selector-row:nth-child(even) {
  /* background-color: #e7e7e7; */
}

.menu-package-items-list {
  padding: 5px 0;
}

.menu-package-items-list-enter {
  opacity: 0;
}

.menu-package-items-list-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-out;
}

.menu-package-items-list-exit {
  opacity: 1;
}

.menu-package-items-list-exit-active {
  opacity: 0;
  transition: opacity 250ms ease-in;
}

@keyframes horizontal-shaking {
  0% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(15px);
  }

  50% {
    transform: translateX(-12px);
  }

  75% {
    transform: translateX(8px);
  }

  85% {
    transform: translateX(-6px);
  }

  90% {
    transform: translateX(5px);
  }

  95% {
    transform: translateX(-3px);
  }

  97% {
    transform: translateX(3px);
  }

  99% {
    transform: translateX(-1px);
  }

  100% {
    transform: translateX(0);
  }

  0% {
    -webkit-transform: translateX(0);
  }

  25% {
    -webkit-transform: translateX(15px);
  }

  50% {
    -webkit-transform: translateX(-12px);
  }

  75% {
    -webkit-transform: translateX(8px);
  }

  85% {
    -webkit-transform: translateX(-6px);
  }

  90% {
    -webkit-transform: translateX(5px);
  }

  95% {
    -webkit-transform: translateX(-3px);
  }

  97% {
    -webkit-transform: translateX(3px);
  }

  99% {
    -webkit-transform: translateX(-1px);
  }

  100% {
    -webkit-transform: translateX(0);
  }
}