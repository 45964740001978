.scan-search-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f0f2f5;
  z-index: 999;
}

.scan-search-container {
  position: relative;
}

.scan-search-list-pane{
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.scan-search-list-pane::-webkit-scrollbar {
  display: none;
}


.scan-search-list-item:active{
  background-color: var(--primary);
  color: #fff;
}
