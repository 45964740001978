.vt-button-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: none;
  text-decoration: none;
  font-weight: bold;
  line-height: 1;
  text-align: center;
  box-shadow: 1px 2px 3px 0px rgba(0, 0, 0, 0.5);
}

.vt-button-container:active {
  transform: translateY(3px);
  transition: transform 100ms ease;
}

.vt-button-icon {
  text-align: center;
}

.vt-button-text {
  align-self: center;
  box-sizing: border-box;
  padding: 3px 5px;
  font-weight: bold;
}